.top,
.right,
.bottom,
.left {
  transition: transform 250ms linear;
}

.top {
  transform: translateX(-111px);
}
.right {
  transform: translateY(-111px);
}
.bottom {
  transform: translateX(111px);
}
.left {
  transform: translateY(111px);
}

.square > .top {
  transform: translateX(0);
}
.square > .right {
  transform: translateY(0);
}
.square > .bottom {
  transform: translateX(0);
}
.square > .left {
  transform: translateY(0);
}
